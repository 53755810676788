import { getUrlParam, cookie } from "../common";
import { COUNTRY_LANG, COUNTRY_TERMS, COUNTRY_POLICY } from "./siteData";
import { delUrlParam, isThirdLogin } from "./utils"
import store from '../../store/index';
import { setLanguage } from "../../lang";
import { constantRoutes } from '../../router/config'
import router from '../../router'

/**
 * 切换到指定站点 - 根据cr切换
 * @param {string} type - cr
 */
 export const toggleLocalByCr = async (cr) => {
    const lang = COUNTRY_LANG.get(cr);
    if(lang) {
        await setLanguage(lang);
    }
}

/**
 * 不需要获取用户信息的url
 */
 export const noUserPath = () => {
    const paths = ["/login", "/register"]
    return paths.indexOf(location.pathname) === -1
}

/**
 * 处理外链进入id系统，携带参数cr:
 * 切换到指定站点
 */
export const handleParamsCrToggle = async () => {
    if(getUrlParam("cr")) {
        const CR = getUrlParam("cr").toUpperCase();
        await toggleLocalByCr(CR);
    }
}

/**
 * 设置多语言&站点信息:
 * 切换到指定站点
 */
 export const handleSetLocal = async () => {
    if(getUrlParam("cr")) {
        handleParamsCrToggle() 
    } else { // 默认站点信息初始化
        await store.dispatch("app/setLanguage", store.getters.language.currentLang);
    }
}

/**
 * 处理外链进入id系统，携带参数x
 * 切换后更新url - 删除url 中x参数
 * @param {string} x - url 参数
 */
 export const handleParamsUrlUpdate = (x) => {
    if(getUrlParam(x)) {
        delUrlParam(x);
    }
}

/**
 * 处理外链进入id系统，携带参数 jkyAppLogin:
 * 非jackery.com主域下(JP)：兼容处理 - by 快捷登录
 */
 export const handleParamsJkyAppLogin = () => {
    if(getUrlParam("jkyAppLogin")) {
        console.log("------handle-app-login")
        const usertoken = getUrlParam("user_token");
        const idtoken = getUrlParam("id_token");
        const mulitipass = getUrlParam("mulitipass");

        handleParamsUrlUpdate('jkyAppLogin') // FIX: jp QUICK LOGIN MULTIPASS ERROR

        cookie.set("_jk_id", idtoken);
        cookie.set("_jk_user", usertoken);

        window.location.href = mulitipass;
    }
}

/**
 * 处理三方登录回跳:
 * emailExist：是否绑定了邮箱，未绑定邮箱直接定向到login绑定后登录
 * ?uid=108393198881405625522&emailExist=1&source=google&belongBrandChannel=1
 * 
 */
 export const handleThirdLoginParams = async () => {
    const {
        emailExist,
        uid,
        source,
        belongBrandChannel
    } = isThirdLogin();
    if(router.currentRoute.value.path !== "/login" && emailExist === 0) {
        router.push({
            path: "/login",
            query: {
                uid,
                source,
                belongBrandChannel
            }
        })
    }
}

/**
 * 处理三方登录回跳:
 * emailExist：是否绑定了邮箱，绑定了邮箱直接登录
 * ?uid=108393198881405625522&emailExist=1&source=google&belongBrandChannel=1
 */
export const handleThirdAutoLogin = async () => {
    const {
        uid,
        source,
        belongBrandChannel
    } = isThirdLogin();
    await store.dispatch('app/thirdLogin', {
        emailExist: 1, 
        belongBrandChannel: getUrlParam("belongBrandChannel"), 
        source: getUrlParam("source"), 
        uid: getUrlParam("uid")
    })
}

/**
 * 获取登录multipass重定向url
 */
 export const getRedirectUrl = (multipass_back) => {
    //  redirect_url
    const redirect_url = multipass_back || getUrlParam("redirect_url") || getUrlParam("return_url") || "";
    if(redirect_url) {
        const url = decodeURIComponent(redirect_url);
        if(url.startsWith('http')) { // 外链
            return url
        } else if(url.startsWith('/')) {
            return `${location.origin}${url}`
        }
    }

    if(location.pathname === "/login" || location.pathname === "/register") {
        return `${location.origin}/`
    }
    return `${location.origin}${location.pathname}`
}


/**
 * 处理id系统登录参数:
 * 外链or内部链接携带参数进行登录场景处理
 * @param {string} returl_url - 登录后返回的地址
 * @param {string} cr - 不需要传什么shopify id之类的，只需要将指定店铺的国家简码带入即可
 */
 export const handleParamsIDLogin = () => {
     const res = {}
    // support 系统进入参数：s_url  s_shop_id 【统一处理support跳转参数：returl_url & cr】

    // 跳十周年活动页面: 页面已下架（暂不处理）


    // https://demo-account.jackery.com/login?jackeryRef=eyJ1IjoiaHR0cHM6Ly9zaG9wLnNpdC1ldS5qYWNrZXJ5LmNvbSIsImlkIjoiNTUwMDUyNDk2MzMiLCJiYWNrIjoiL2FjY291bnQvb3ZlcnZpZXciLCAib3JpZ2luYWwiOiAiaHR0cHM6Ly9zaG9wLnNpdC1ldS5qYWNrZXJ5LmNvbS9wYWdlcy90ZXN0LTIifQ==


    let registerAppId = getUrlParam("registerAppId")

    
    if(registerAppId) {
        res.registerAppId = decodeURIComponent(registerAppId);
    }
}

const flat = (arr) => {
    const res = []
    const dfs = (arr, str) => {
    arr.forEach(item => {
        if(!item.children) {
            if(item.path instanceof Array) {
                item.path.forEach(item => {
                    res.push(str+item)
                })
                return
            }
            if(!item.path) {
                res.push(str + '/')
                return res.push(str)
            }
            if(item.path[0] === '/') {
                res.push(str + item.path + '/')
                return res.push(str + item.path)
            } else {
                res.push(str + '/' + item.path + '/')
                return res.push(str + '/' + item.path)
            }
        }
        else {
            // 判断如果是数组继续递归遍历
            dfs(item.children, str+item.path)
        }
    })
    }
    dfs(arr, '')
    return res
}

export const whiteList = (routes = constantRoutes) => {
    const res = flat(routes)
    return res
}

/**
 * 设置店铺config
 * shopId               店铺id
 * lang                 语言
 * env                  环境标识 demo uat prod
 * cr                   国家简码
 * platform             平台：account | shopify | aem
 * terms_url            政策条款url
 * policy_url           隐私条款url
 */
    export const ENV_MODE = new Map([
        ['development', 'demo'],
        ['production', 'prod'],
        ['demo', 'demo'],
        ['uat', 'uat']
    ]) 

    export const ENV_API = new Map([
        ['development', 'https://demo-api.myjackery.com'],
        ['production', 'https://api.myjackery.com'],
        ['demo', 'https://demo-api.myjackery.com'],
        ['uat', 'https://api-uat.myjackery.com']
    ]) 

 export const setShopConfig = () => {
    window.pageInfo = {
        api: ENV_API.get(import.meta.env.MODE),
        shopId: store.getters.language.current.shopify_shop_id,
        lang: COUNTRY_LANG.get(store.getters.language.current.cr).split("-")[0] || "en",
        env: ENV_MODE.get(import.meta.env.MODE),
        cr: store.getters.language.current.cr,
        platform: "account",
        terms_url: COUNTRY_TERMS.get(store.getters.language.current.cr),
        policy_url: COUNTRY_POLICY.get(store.getters.language.current.cr),
        currency: {
          currency: store.getters.language.current.currency,
        }
    }

    if(window.jackeryLoginApp && window.jackeryLoginApp.changeConfig) {
        window.jackeryLoginApp.changeConfig({...window.pageInfo})
    }
}

